import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled, { keyframes } from "styled-components"
import Slider from "react-slick"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"
import { allLangs } from "../../utils/configClient"
import { Box } from "../../components/Core"

const BoxBottom = styled(Box)`
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  z-index: 2;
  bottom: -1px;
  transform: rotate(180deg);
  svg {
    display: block;
    width: calc(124% + 1.3px);
    height: 116px;
    position: relative;
    left: 50%;

    transform: translateX(-50%) rotateY(180deg);
  }
`

const keyKenburn = keyframes`
  0%{
    transform-origin: center;
    transform: scale(1.1);
  }
  100%{
    transform: scale(1.0);
  }
`

const SliderStyled = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 100;
  }
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 20px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 32px;
    opacity: 1;
    color: #fff;
  }

  a:focus {
    color: #fff !important;
    background: transparent !important;
  }
`

const SliderItemSltyled = styled(Box)`
  width: 100%;
  background: #222;
  position: relative;

  .slick-slide.slick-active.slick-current & .cover-image {
    animation: ${keyKenburn} 7s linear;
  }
  .slick-slide & .text-wrapper {
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-out 0.5s;
  }
  .slick-slide.slick-active.slick-current & .text-wrapper {
    opacity: 1;
    visibility: visible;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

const CoverImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
`

const SliderItem = ({ image, titleBig, titleSmall, text }) => {
  const gContext = useContext(GlobalContext)

  return (
    <SliderItemSltyled
      minHeight={["500px", null, null, "600px", "700px"]}
      py="70px"
      pt="110px"
      className="text-center text-white d-flex align-items-center justify-content-center"
    >
      {image && (
        <CoverImage
          className="cover-image"
          fluid={image.fluid}
          alt={titleBig ? titleBig.text : ""}
        />
      )}
      <Overlay />

      <div
        className="position-relative text-wrapper w-100 h-100"
        css={`
          z-index: 10;
        `}
      >
        <Container fluid>
          <Row className="align-items-center justify-content-center">
            <Col>
              {titleBig && (
                <h1
                  className={`${
                    gContext.currentLang === allLangs.default
                      ? "display-5"
                      : "display-6"
                  } text-white font-weight-bold mb-5`}
                  css={`
                    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
                    line-height: 1.125;
                  `}
                >
                  {titleBig.text}
                </h1>
              )}
              {titleSmall && (
                <h3
                  className="text-white mb-4"
                  css={`
                    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
                  `}
                >
                  {titleSmall.text}
                </h3>
              )}
              {text && (
                <Box
                  mt={4}
                  css={`
                    padding: 16px 24px;
                    background-color: rgba(0, 0, 0, 0.33);
                    max-width: 1100px;
                    margin: 0 auto;
                  `}
                >
                  <p className="text-white mb-0">{text.text}</p>
                </Box>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </SliderItemSltyled>
  )
}

const HeroSlider = () => {
  const { allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicHomePage {
        nodes {
          lang
          data {
            hero_slider {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              title_big {
                text
              }
              title_small {
                text
              }
              text {
                text
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: false,
  }

  return (
    <Box className="position-relative">
      <BoxBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2600 131.1"
          preserveAspectRatio="none"
          className="divider-shape"
        >
          <path d="M0 0L2600 0 2600 69.1 0 0z"></path>
          <path
            css={`
              opacity: 0.5;
            `}
            d="M0 0L2600 0 2600 69.1 0 69.1z"
          ></path>
          <path
            css={`
              opacity: 0.25;
            `}
            d="M2600 0L0 0 0 130.1 2600 69.1z"
          ></path>
        </svg>
      </BoxBottom>
      {pData?.hero_slider.length > 0 && (
        <SliderStyled {...settings} className="overflow-hidden">
          {pData?.hero_slider.map(
            ({ image, title_big, title_small, text }, index) => (
              <SliderItem
                key={index}
                image={image}
                titleBig={title_big}
                titleSmall={title_small}
                text={text}
              />
            )
          )}
        </SliderStyled>
      )}
    </Box>
  )
}

export default HeroSlider
