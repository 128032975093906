import React, { useContext } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { Section, Box, Title, Text } from "../../components/Core"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"

import ShapeSection from "../../sections/common/ShapeSection"
import bgImg from "../../assets/images/bg5.jpg"

const ColStyled = styled(Col)`
  .box-parent {
    position: relative;
    &:hover {
      .img-container {
        opacity: 0;
      }
      .content {
        opacity: 1;
        z-index: 10;
      }
    }
  }
  .img-container {
    position: relative;
    opacity: 1;
    transition: all 0.3s ease-in;
  }
  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: 1;
    background: ${({ theme }) => theme.colors.primary};
    transition: all 0.3s ease-out;
  }
`

const PartnerItem = ({ image, name, url }) => (
  <ColStyled lg={3} className="mb-5">
    <Box
      className="d-flex align-items-start box-parent h-100"
      css={`
        min-height: 130px;
      `}
    >
      <Box className="img-container d-flex justify-content-center align-items-center h-100 w-100">
        {image && (
          <Img
            fluid={image.fluid}
            alt={""}
            className="img-fluid"
            css={`
              min-width: 140px;
              width: 100%;
            `}
          />
        )}
      </Box>
      <Box className="content d-flex flex-column justify-content-center align-items-center text-center p-3 overflow-hidden">
        {name && (
          <Title variant="card" color="white" mb={3}>
            {name.text}
          </Title>
        )}
        {url && (
          <a href={url.text} target="_blank">
            <Button
              variant="outline-light"
              size="sm"
              css={`
                font-weight: 600;
                &:hover {
                  color: ${({ theme }) => theme.colors.primary} !important;
                }
              `}
            >
              Visit
            </Button>
          </a>
        )}
      </Box>
    </Box>
  </ColStyled>
)

const Partners = () => {
  const { allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicHomePage {
        nodes {
          lang
          data {
            partners_title {
              text
            }
            partners_bg {
              url
            }
            partners_group {
              name {
                text
              }
              url {
                text
              }

              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <>
      <ShapeSection bg={pData?.partners_bg ? pData?.partners_bg.url : bgImg}>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="6">
              <Title color="light" my={3}>
                {pData?.partners_title && (
                  <Title mb={3} color="light">
                    {pData?.partners_title.text}
                  </Title>
                )}
              </Title>
            </Col>
          </Row>
        </Container>
      </ShapeSection>
      <Section bg="#fff">
        <Container>
          <Row>
            {pData?.partners_group.length > 0 &&
              pData?.partners_group.map(({ image, name, url }, index) => (
                <PartnerItem key={index} image={image} name={name} url={url} />
              ))}
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Partners
