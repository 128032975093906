import React, { useContext } from "react"
import { Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Slider from "react-slick"

import { Box, Section } from "../../components/Core"
import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"

import { breakpoints } from "../../utils"

const SliderStyled = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 100;
  }
  .slick-prev {
    left: -10px;
  }
  .slick-next {
    right: -5px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 25px;
    opacity: 1;
    color: #000;
  }

  a:focus {
    color: #000 !important;
    background: transparent !important;
  }
`

const SliderItemSltyled = styled(Box)``

const SliderItem = ({ image, onClick }) => (
  <SliderItemSltyled
    className="text-center text-white d-flex align-items-center justify-content-center px-3"
    css={`
      height: 300px;
    `}
    onClick={onClick}
  >
    {image && (
      <Img className="cover-image w-100" fluid={image.fluid} alt={""} />
    )}
  </SliderItemSltyled>
)

const Certificates = () => {
  const { allPrismicHonor } = useStaticQuery(graphql`
    query {
      allPrismicHonor(sort: { order: DESC, fields: data___year }) {
        nodes {
          lang
          uid
          data {
            title {
              text
            }
            year
            certificates {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const honorNodes = itemsByLang(allPrismicHonor.nodes, gContext.currentLang)

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: breakpoints.xl,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakpoints.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section className="pt-0">
      <Container>
        <SliderStyled {...settings}>
          {honorNodes.map(({ data, uid }, index) => {
            return (
              data.certificates.length > 0 &&
              data.certificates.map(
                ({ image }, index) =>
                  image && (
                    <SliderItem
                      key={index}
                      image={image}
                      onClick={() => {
                        gContext.setLightBoxHonor({ data: data, uid: uid })
                        gContext.setLightBoxImage(image)
                        gContext.toggleLightBox()
                      }}
                    />
                  )
              )
            )
          })}
        </SliderStyled>
      </Container>
    </Section>
  )
}

export default Certificates
