import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"
import { slugAbout } from "../../utils/slugs"

import { Title, Box, Span } from "../../components/Core"
import ShapeSection from "../../sections/common/ShapeSection"
import bgImg from "../../assets/images/bg6.jpg"

const Quote = () => {
  const { allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicHomePage {
        nodes {
          lang
          data {
            quote_title {
              text
            }
            quote_bg {
              url
            }
            quote_btn_text {
              text
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <>
      <ShapeSection bg={pData?.quote_bg ? pData?.quote_bg.url : bgImg}>
        <Container fluid>
          <Row className="text-center justify-content-center">
            <Col lg="12">
              {pData?.quote_title && (
                <Title color="light" mt={4}>
                  "{pData?.quote_title.text}"
                </Title>
              )}

              <Box pt={4}>
                <Title variant="card">
                  <Link to={slugAbout(gContext.currentLang)}>
                    <Span
                      color="light"
                      css={`
                        text-decoration: underline;
                        cursor: pointer;
                      `}
                    >
                      {pData?.quote_btn_text ? pData?.quote_btn_text.text : ""}
                    </Span>
                  </Link>
                </Title>
              </Box>
            </Col>
          </Row>
        </Container>
      </ShapeSection>
    </>
  )
}

export default Quote
