import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"
import { slugAbout } from "../../utils/slugs"
import { Section, Box, Title, Text, Button } from "../../components/Core"

const About = () => {
  const { allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicHomePage {
        nodes {
          lang
          data {
            about_title {
              text
            }
            about_text {
              text
            }
            about_button_text {
              text
            }
            about_gallery {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <Section className="pb-0">
      <Container>
        <Row>
          <Col lg="6" className="mb-5 mb-lg-0">
            {pData?.about_title && (
              <Title mb={4} color="primary">
                {pData?.about_title.text}
              </Title>
            )}
            {pData?.about_text && <Text mb={5}>{pData?.about_text.text}</Text>}
            {pData?.about_button_text && (
              <Link to={slugAbout(gContext.currentLang)}>
                <Button size="lg">{pData?.about_button_text.text}</Button>
              </Link>
            )}
          </Col>
          <Col lg="6">
            <Row>
              {pData?.about_gallery.length > 0 &&
                pData?.about_gallery.length < 5 &&
                pData?.about_gallery.map((item, index) => (
                  <Col md="6" key={index} className="mb-4">
                    {item.image && (
                      <Box height="200px">
                        <Img
                          fluid={item.image.fluid}
                          alt=""
                          className="img-fluid w-100 h-100"
                          css={`
                            img {
                              object-fit: contain !important;
                              width: 100%;
                            }
                          `}
                        />
                      </Box>
                    )}
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default About
