import React, { useState, useContext } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Slider from "react-slick"

import GlobalContext from "../../contexts/GlobalContext"
import { Section, Box, Title, Text } from "../../components/Core"
import ShapeSection from "../../sections/common/ShapeSection"
import { breakpoints } from "../../utils"
import { slugNews } from "../../utils/slugs"
import { itemsByLang } from "../../utils/helperFn"

import bgImg from "../../assets/images/bg1.jpg"
import { FaCalendar } from "react-icons/fa"

const SliderStyled = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 100;
  }
  .slick-prev {
    left: -10px;
  }
  .slick-next {
    right: -5px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 25px;
    opacity: 1;
    color: #000;
  }

  a:focus {
    color: #000 !important;
    background: transparent !important;
  }
  .slick-slide:focus {
    outline: none !important;
  }
`

const SliderItemSltyled = styled(Box)`
  &:focus {
    outline: none !important;
  }
`

const SliderItem = ({ image, slug, title, excerpt, date }) => {
  const gContext = useContext(GlobalContext)

  return (
    <SliderItemSltyled className="pb-5 mx-1">
      <Link to={slugNews(slug, gContext.currentLang)}>
        <Box>
          <Box minWidth="120px" minHeight="120px">
            {image && (
              <Img
                fluid={image.fluid}
                alt={""}
                className="img-fluid d-block"
                css={`
                  height: 200px;
                `}
              />
            )}
          </Box>
          <Box
            mt="-40px"
            p={"24px"}
            mx="3"
            bg="#f5f5f5"
            className="position-relative"
            css={`
              z-index: 10;
            `}
          >
            {title && (
              <Title variant="card" mb={1} fontSize="18px!important">
                {title.text}
              </Title>
            )}
            <Box mb={2}>
              <small>
                <FaCalendar className="mr-1" /> {date}
              </small>
            </Box>
            <hr />
            {excerpt && <Text variant="small">{excerpt.text}</Text>}
          </Box>
        </Box>
      </Link>
    </SliderItemSltyled>
  )
}

const News = () => {
  const { allPrismicNews, allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicHomePage {
        nodes {
          lang
          data {
            news_bg {
              url
            }
            news_title {
              text
            }
            load_more_button_text {
              text
            }
          }
        }
      }
      allPrismicNews(sort: { order: DESC, fields: data___date }) {
        nodes {
          uid
          lang
          data {
            date(formatString: "MMMM DD, yyyy")
            title {
              text
            }
            excerpt {
              text
            }
            cover_image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const newItemsByLang = itemsByLang(allPrismicNews.nodes, gContext.currentLang)

  const [numOfPosts, setNumOfPosts] = useState(3)

  const loadMore = () => {
    setNumOfPosts(numOfPosts + 3)
  }

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <>
      <ShapeSection bg={pData?.news_bg ? pData?.news_bg.url : bgImg}>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="6">
              {pData?.news_title && (
                <Title color="light" my={3}>
                  {pData?.news_title.text}
                </Title>
              )}
            </Col>
          </Row>
        </Container>
      </ShapeSection>
      <Section>
        <Container>
          <Row className="justify-content-center">
            {newItemsByLang.length > 0 &&
              newItemsByLang.map(
                ({ data, uid }, index) =>
                  index < numOfPosts && (
                    <Col lg="4" key={index}>
                      <SliderItem
                        key={index}
                        image={data.cover_image}
                        title={data.title}
                        date={data.date}
                        title={data.title}
                        excerpt={data.excerpt}
                        slug={uid}
                      />
                    </Col>
                  )
              )}
          </Row>
          {newItemsByLang.length > numOfPosts && (
            <div className="d-flex justify-content-center">
              <Button variant="outline-primary" onClick={loadMore}>
                {pData?.load_more_button_text?.text || "Load More"}
              </Button>
            </div>
          )}
        </Container>
      </Section>
    </>
  )
}

export default News
