import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { FaChevronRight } from "react-icons/fa"

import GlobalContext from "../../contexts/GlobalContext"

import { Section, Box, Title } from "../../components/Core"

import ShapeSection from "../../sections/common/ShapeSection"
import bgImg from "../../assets/images/bg5.jpg"
import { slugMap } from "../../utils/slugs"
import { itemsByLang } from "../../utils/helperFn"

const ColStyled = styled(Col)`
  &:hover {
    .content .title {
      transform: translateX(-0.5rem);
    }
    .content .icon {
      transform: translateX(0.25rem);
      opacity: 1;
      visibility: visible;
    }
  }

  .content {
    background: ${({ theme }) => theme.colors.primary};

    .title {
      transition: all 0.25s;
    }
    .icon {
      transform: translateX(-0.25rem);
      opacity: 0;
      visibility: hidden;
      transition: all 0.25s;
    }
  }
`

const RepresentativeItem = ({ title, slug }) => {
  const gContext = useContext(GlobalContext)
  return (
    <ColStyled lg={6} xl={3} className="mb-4 mb-xl-0">
      <Link to={slugMap(slug, gContext.currentLang)} className="d-block">
        <Box
          className="content d-flex justify-content-center align-items-center text-center px-2 overflow-hidden"
          py={4}
        >
          {title && (
            <Title className="title" variant="card" color="white" mb={0}>
              {title.text}
            </Title>
          )}
          <FaChevronRight className="icon mt-1" color="#fff" size="16" />
        </Box>
      </Link>
    </ColStyled>
  )
}

const Representatives = () => {
  const { allPrismicContinent, allPrismicHomePage } = useStaticQuery(graphql`
    query {
      allPrismicContinent(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
          }
        }
      }

      allPrismicHomePage {
        nodes {
          lang
          data {
            representative_title {
              text
            }
            representative_bg {
              url
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)
  const mapItemsByLang = itemsByLang(
    allPrismicContinent.nodes,
    gContext.currentLang
  )

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <>
      <ShapeSection
        bg={pData?.representative_bg ? pData?.representative_bg.url : bgImg}
      >
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="6">
              {pData?.representative_title && (
                <Title color="light">{pData?.representative_title.text}</Title>
              )}
            </Col>
          </Row>
        </Container>
      </ShapeSection>
      <Section bg="#fff">
        <Container>
          <Row>
            {mapItemsByLang.length > 0 &&
              mapItemsByLang.map(({ data, uid }, index) => (
                <RepresentativeItem key={index} title={data.title} slug={uid} />
              ))}
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Representatives
