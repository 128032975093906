import React, { useState, useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rgba } from "polished"

import GlobalContext from "../../contexts/GlobalContext"

import { slugCat, slugSubCat } from "../../utils/slugs"
import { itemsByLang } from "../../utils/helperFn"
import { Section, Box, Title, Text } from "../../components/Core"
import { result } from "lodash"

const ProductItemSltyled = styled(Box)`
  width: 100%;
  background: #222;
  position: relative;
  z-index: 1;
  & .bg-box {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  &::before {
    position: absolute;
    content: "";
    opacity: 0;
    top: 30px;
    right: 20px;
    bottom: 30px;
    left: 20px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: scale(0, 1);
    transform-origin: 0 0;
    transition: opacity 0.35s, transform 0.35s;
  }
  &::after {
    position: absolute;
    content: "";
    opacity: 0;
    top: 20px;
    right: 30px;
    bottom: 20px;
    left: 30px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: scale(1, 0);
    transform-origin: 100% 0;
    transition: opacity 0.35s, transform 0.35s;
  }
  &:hover::before,
  &:hover::after {
    opacity: 1;
    transform: scale(1);
  }
  &:hover::after {
    transition-delay: 0.15s;
  }
`
const Logo = styled(Img)``

const LinkCatTitle = styled(Link)`
  color: white !important;
  &:hover,
  &:active,
  &:focus,
  &.active {
    text-decoration: none !important;
    outline: none !important;
    color: ${({ theme, hoverColor }) =>
      hoverColor ? hoverColor : theme.colors.primary} !important;
  }
`

const LinkSubCatTitle = styled(Link)`
  font-size: 14px;
  color: white !important;

  &:hover,
  &:active,
  &:focus,
  &.active {
    font-weight: 600;
    text-decoration: none !important;
    outline: none !important;
    color: ${({ theme, hoverColor }) =>
      hoverColor ? hoverColor : theme.colors.primary} !important;
  }
`

const CoverImageHome = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  ${ProductItemSltyled}:hover & {
    z-index: -4;
  }
`
const CoverImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -3;
`

const ProductItem = ({
  logo,
  coverImageHome,
  coverImage,
  title,
  uid,
  subCats,
  data,
  index,
}) => {
  const { color_brand, color_hover, color_active } = data
  const [coverImageProd, setCoverImageProd] = useState(null)

  const gContext = useContext(GlobalContext)

  return (
    <ProductItemSltyled
      px="50px"
      py="70px"
      className="text-white d-flex align-items-center justify-content-center"
    >
      <div className="bg-box" />

      {coverImageHome && (
        <CoverImageHome
          className="cover-image"
          fluid={coverImageHome.fluid}
          alt={title ? title.text : ""}
        />
      )}

      <CoverImage
        className="cover-image"
        fluid={coverImageProd ? coverImageProd.fluid : coverImage.fluid}
        alt={title ? title.text : ""}
      />

      <div
        className="position-relative text-wrapper w-100 h-100"
        css={`
          z-index: 10;
        `}
      >
        <Box>
          {logo && (
            <Box mb={3}>
              <Link to={slugCat(uid, gContext.currentLang)}>
                <Logo
                  className="logo-image"
                  fixed={logo.fixed}
                  alt={title ? title.text : ""}
                />
              </Link>
            </Box>
          )}
          {title && (
            <Box mb={4}>
              <h5 className="mb-0">
                <LinkCatTitle
                  to={slugCat(uid, gContext.currentLang)}
                  hoverColor={color_brand}
                >
                  {title.text}
                </LinkCatTitle>
              </h5>
            </Box>
          )}
          {subCats.length > 0 && (
            <ul className="pl-3">
              {subCats.map(item => (
                <li
                  key={item.uid}
                  css={`
                    margin-bottom: 5px !important;
                  `}
                >
                  <LinkSubCatTitle
                    to={slugSubCat(
                      item.uid,
                      item.data.category.uid,
                      gContext.currentLang
                    )}
                    hoverColor={color_brand}
                    onMouseOver={() => {
                      setCoverImageProd(item.data.cover_image)
                    }}
                    onMouseOut={() => setCoverImageProd(null)}
                  >
                    {item.data.title.text}
                  </LinkSubCatTitle>
                </li>
              ))}
            </ul>
          )}
        </Box>
      </div>
    </ProductItemSltyled>
  )
}

const Products = () => {
  const {
    allPrismicCategory,
    allPrismicSubCategory,
    allPrismicHomePage,
  } = useStaticQuery(graphql`
    {
      allPrismicCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
            intro {
              text
            }
            logo {
              fixed(width: 100) {
                ...GatsbyPrismicImageFixed
              }
            }
            cover_image_home {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
            cover_image {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
            color_brand
            color_hover
            color_active
          }
        }
      }
      allPrismicSubCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
            cover_image {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
            category {
              uid
            }
          }
        }
      }

      allPrismicHomePage {
        nodes {
          lang
          data {
            product_section_title {
              text
            }
            product_section_text {
              text
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const catsByLang = itemsByLang(allPrismicCategory.nodes, gContext.currentLang)

  const subCatsByLang = itemsByLang(
    allPrismicSubCategory.nodes,
    gContext.currentLang
  )

  const subCatsByCat = catUid => {
    return subCatsByLang.filter(({ data }) => data.category.uid === catUid)
  }

  const prismicHomePage = itemsByLang(
    allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicHomePage?.data

  return (
    <Section>
      <Container>
        <Row>
          <Col lg="6">
            {pData?.product_section_title && (
              <Title mb={3} color="primary">
                {pData?.product_section_title.text}
              </Title>
            )}
            {pData?.product_section_text && (
              <Text mb={5}>{pData?.product_section_text.text}</Text>
            )}
          </Col>
        </Row>

        <Row className="justify-content-center">
          {catsByLang.map(({ uid, data }, index) => {
            return (
              <Col lg="4" className="mb-5 d-flex flex-grow-1" key={uid}>
                <ProductItem
                  uid={uid}
                  logo={data.logo}
                  coverImageHome={data.cover_image_home}
                  coverImage={data.cover_image}
                  title={data.title}
                  subCats={subCatsByCat(uid)}
                  data={data}
                  index={index}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Section>
  )
}

export default Products
